import React, { useState } from 'react';
import Select from 'react-select';
import Acnavbar from '../components/Acnavbar';
import ProductDescription from '../components/ProductDescription';
import Footer from '../components/footer';
import Contact from '../components/Contact';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import { Col, Container, Row } from 'react-bootstrap';
import productCards from '../json/product-cards.json';
import '../assets/scss/index.scss';
import BlogCard from '../components/BlogCard';
import OtherServicesCard from '../components/OtherServicesCard';
import { graphql } from 'gatsby';

function BlogCategory({ pageContext, data }: any) {
  const filteredArticles = data.allContentfulBlogArticle.edges.map((node: any) => node.node);

  return (
    <Wrapper>
      <Seo title={pageContext.seoTitle} description={pageContext.seoDescription} />
      <div>
        <Acnavbar home={false} pathname={'blogs'} logo={''} logoLogic={false} />
        <ProductDescription
          topheader='BLOG CATEGORY'
          header={pageContext.title}
          headerText={pageContext.description.description}
          showButton={true}
        />
        <div className='py-lg-5 py-4'>
          <Container>
            <div className=''>
              <div className='fnt-lg-32 fnt-md-32 fnt-sm-20 fw-700 lh-sm-22 mb-2 mb-lg-4 pb-1 pb-lg-0 text-center'>
                Seamlessly soar to the cloud: Unlocking limitless possibilities through intelligent
                and secure migration.
              </div>
            </div>
            <BlogCard data={filteredArticles} />
          </Container>
        </div>

        <div className='common-background px-lg-5'>
          <Container className='mx-lg-9 px-lg-7 py-4 py-lg-5'>
            <h2 className='fnr-lg-32 fw-700 pb-3 pb-lg-4 text-center text-white'>Our Services</h2>
            <Row xs={1} sm={1} md={3} lg={3} className='pe-0'>
              {productCards.map((othercard: any) => (
                <Col key={othercard.id} className='pb-4'>
                  <OtherServicesCard {...othercard} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <Contact />
        <Footer />
      </div>
    </Wrapper>
  );
}
export default BlogCategory;

export const data = graphql`
  query PagesQuery($title: String!) {
    allContentfulBlogArticle(filter: { categories: { elemMatch: { title: { eq: $title } } } }) {
      edges {
        node {
          id
          title
          timeToRead
          slug
          description {
            description
          }
          createdAt
          author {
            designation
            name
            profileImage {
              file {
                contentType
                fileName
                url
              }
            }
          }
          previewImage {
            id
            title
            file {
              url
            }
            gatsbyImageData(layout: FIXED)
          }
          categories {
            id
            title
            contentful_id
            slug
          }
          body {
            raw
          }
        }
      }
    }
  }
`;
